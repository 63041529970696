import { Cookies, useCookies } from "react-cookie";
import { settings } from "../settings";

const cookies = new Cookies();

export const getAuthToken = () => {
  return cookies.get("access_token");
};

export const getRefreshToken = () => {
  return cookies.get("refresh_token");
};

export const setAuthToken = (authToken?: string) => {
  if (!authToken) {
    throw new Error("Auth token may not be empty");
  }
  const tokenExpires = getJwtExpiration(authToken);
  cookies.set("access_token", authToken, {
    path: "/",
    domain: settings.COOKIE_DOMAIN,
    expires: tokenExpires,
    secure: process.env.NODE_ENV === "production",
    sameSite: "lax",
  });
};

export const setRefreshToken = (refreshToken?: string) => {
  if (!refreshToken) {
    throw new Error("Refresh token may not be empty");
  }
  const refreshTokenExpire = getJwtExpiration(refreshToken);
  cookies.set("refresh_token", refreshToken, {
    path: "/",
    domain: settings.COOKIE_DOMAIN,
    expires: refreshTokenExpire,
    secure: process.env.NODE_ENV === "production",
    sameSite: "lax",
  });
};

function getJwtExpiration(token: string): Date {
  try {
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    if (decodedPayload.exp) {
      return new Date(decodedPayload.exp * 1000);
    }

    throw new Error("Invalid JWT token");
  } catch (error) {
    throw new Error("Invalid JWT token");
  }
}

export const useExportTokens = () => {
  return useCookies(["access_token", "refresh_token"])[0];
};
