import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { settings } from "../settings";
import { getAuthToken } from "./token";

// Create the axios instance without baseURL
const authClient = Axios.create();

authClient.interceptors.request.use(
  (config) => {
    // Dynamically set the baseURL from settings just before the request
    config.baseURL = settings.API_URL;

    const accessToken = getAuthToken();
    if (accessToken) {
      config.headers["Authorization"] = `${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const createAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return authClient({ ...config, ...options }).then((response) => ({
    ...response,
    data: response.data as T,
  }));
};

// In some case with react-query and swr you want to be able to override the return error type
export type ErrorType<Error> = AxiosError<Error>;
