import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setAuthToken, setRefreshToken } from "../api/token";

export const OAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const refreshToken = searchParams.get("refreshToken");

  useEffect(() => {
    if (token && refreshToken) {
      setAuthToken(token);
      setRefreshToken(refreshToken);

      const redirect =
        localStorage.getItem("r") ?? window.location.pathname + "/..";
      window.location.replace(redirect);
    }
  }, [refreshToken, token]);

  return <></>;
};
